import config from 'config/config';
import {
  brandLinks,
  brands,
  links,
  locationLinks,
  socialMediaLinks,
} from '../Navbar/links.tsx';
import { Event } from '../../../../components/Tracking/index.js';

export const buyLinks = [
  {
    label: "Men's",
    gaInstance: () => {
      Event(
        'Link',
        `Click on ${config.LUXITY_SHOP_URL}/products/mens`,
        "Men's",
      );
    },
    href: `${config.LUXITY_SHOP_URL}/products/mens`,
    trackEvent: true,
    target: '_self',
  },
  {
    label: "Women's",
    gaInstance: () => {
      Event(
        'Link',
        `Click on ${config.LUXITY_SHOP_URL}/products/womens`,
        "Womens's",
      );
    },
    href: `${config.LUXITY_SHOP_URL}/products/womens`,
    trackEvent: true,
    target: '_self',
  },
  {
    label: 'New',
    gaInstance: () => {
      Event(
        'Link',
        `Click on ${config.LUXITY_SHOP_URL}/products/brands`,
        'New',
      );
    },
    href: `${config.LUXITY_SHOP_URL}/products/brands`,
    trackEvent: true,
    target: '_self',
  },
  {
    label: 'Bags',
    gaInstance: () => {
      Event('Link', `Click on ${config.LUXITY_SHOP_URL}/products/bags`, 'Bags');
    },
    href: `${config.LUXITY_SHOP_URL}/products/bags`,
    trackEvent: true,
    target: '_self',
  },
  {
    label: 'Shoes',
    gaInstance: () => {
      Event(
        'Link',
        `Click on ${config.LUXITY_SHOP_URL}/products/shoes`,
        'Shoes',
      );
    },
    href: `${config.LUXITY_SHOP_URL}/products/shoes`,
    trackEvent: true,
    target: '_self',
  },
  {
    label: 'Clothing',
    gaInstance: () => {
      Event(
        'Link',
        `Click on ${config.LUXITY_SHOP_URL}/products/apparel`,
        'Clothing',
      );
    },
    href: `${config.LUXITY_SHOP_URL}/products/apparel`,
    trackEvent: true,
    target: '_self',
  },
  {
    label: 'Accessories',
    gaInstance: () => {
      Event(
        'Link',
        `Click on ${config.LUXITY_SHOP_URL}/products/accessories`,
        'Accessories',
      );
    },
    href: `${config.LUXITY_SHOP_URL}/products/accessories`,
    trackEvent: true,
    target: '_self',
  },
  {
    label: 'Watches',
    gaInstance: () => {
      Event(
        'Link',
        `Click on ${config.LUXITY_SHOP_URL}/products/watches`,
        'Watches',
      );
    },
    href: `${config.LUXITY_SHOP_URL}/products/watches`,
    trackEvent: true,
    target: '_self',
  },
];

export const popularLinks = [
  {
    label: brands.LOUIS_VUITTON,
    gaInstance: () => {
      Event(
        'Link',
        `Click on ${brandLinks.LOUIS_VUITTON}`,
        brands.LOUIS_VUITTON,
      );
    },
    href: brandLinks.LOUIS_VUITTON,
    trackEvent: true,
    target: '_self',
  },
  {
    label: brands.GUCCI,
    gaInstance: () => {
      Event('Link', `Click on ${brandLinks.GUCCI}`, brands.GUCCI);
    },
    href: brandLinks.GUCCI,
    trackEvent: true,
    target: '_self',
  },
  {
    label: brands.CHANEL,
    gaInstance: () => {
      Event('Link', `Click on ${brandLinks.CHANEL}`, brands.CHANEL);
    },
    href: brandLinks.CHANEL,
    trackEvent: true,
    target: '_self',
  },
  {
    label: brands.CHRISTIAN_LOUBOUTIN,
    gaInstance: () => {
      Event(
        'Link',
        `Click on ${brandLinks.CHRISTIAN_LOUBOUTIN}`,
        brands.CHRISTIAN_LOUBOUTIN,
      );
    },
    href: brandLinks.CHRISTIAN_LOUBOUTIN,
    trackEvent: true,
    target: '_self',
  },
  {
    label: brands.CHRISTIAN_DIOR,
    gaInstance: () => {
      Event(
        'Link',
        `Click on ${brandLinks.CHRISTIAN_DIOR}`,
        brands.CHRISTIAN_DIOR,
      );
    },
    href: brandLinks.CHRISTIAN_DIOR,
    trackEvent: true,
    target: '_self',
  },
  {
    label: brands.BURBERRY,
    gaInstance: () => {
      Event('Link', `Click on ${brandLinks.BURBERRY}`, brands.BURBERRY);
    },
    href: brandLinks.BURBERRY,
    trackEvent: true,
    target: '_self',
  },
  {
    label: brands.ROLEX,
    gaInstance: () => {
      Event('Link', `Click on ${brandLinks.ROLEX}`, brands.ROLEX);
    },
    href: brandLinks.ROLEX,
    trackEvent: true,
    target: '_self',
  },
  {
    label: brands.CARTIER,
    gaInstance: () => {
      Event('Link', `Click on ${brandLinks.CARTIER}`, brands.CARTIER);
    },
    href: brandLinks.CARTIER,
    trackEvent: true,
    target: '_self',
  },
];

export const sellLinks = [
  {
    label: 'Sell To Us',
    gaInstance: () => {
      Event('Link', `Click on ${links.sellToUsLink}`, 'Sell To Us');
    },
    href: `${links.sellToUsLink}`,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Watches',
    gaInstance: () => {
      Event('Link', `Click on ${links.sellYourWatchLink}`, 'Watches');
    },
    href: `${links.sellYourWatchLink}`,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Jewellery',
    gaInstance: () => {
      Event('Link', `Click on ${links.sellYourJewelleryLink}`, 'Jewellery');
    },
    href: `${links.sellYourJewelleryLink}`,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Handbags',
    gaInstance: () => {
      Event('Link', `Click on ${links.sellYourHandbagLink}`, 'Handbags');
    },
    href: `${links.sellYourHandbagLink}`,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Shoes',
    gaInstance: () => {
      Event('Link', `Click on ${links.sellYourShoesLink}`, 'Shoes');
    },
    href: `${links.sellYourShoesLink}`,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Clothing',
    gaInstance: () => {
      Event('Link', `Click on ${links.sellYourClothingLink}`, 'Clothing');
    },
    href: `${links.sellYourClothingLink}`,
    trackEvent: true,
    target: '_blank',
  },
];

export const companyLinks = [
  {
    label: 'Our Story',
    gaInstance: () => {
      Event('Link', `Click on ${links.ourStoryLink}`, 'Our Story');
    },
    href: `${links.ourStoryLink}`,
    trackEvent: true,
    target: '_self',
  },
  {
    label: 'Blog',
    gaInstance: () => {
      Event('Link', `Click on ${links.blogLink}`, 'Blog');
    },
    href: `${links.blogLink}`,
    trackEvent: true,
    target: '_self',
  },
  {
    label: 'FAQs',
    gaInstance: () => {
      Event('Link', `Click on ${links.faqLink}`, 'FAQs');
    },
    href: `${links.faqLink}`,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Shipping & Returns',
    gaInstance: () => {
      Event('Link', `Click on ${links.shippingLink}`, 'Shipping & Returns');
    },
    href: `${links.shippingLink}`,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Terms & Conditions',
    gaInstance: () => {
      Event(
        'Link',
        `Click on ${links.termsConditionsLink}`,
        'Terms & Conditions',
      );
    },
    href: `${links.termsConditionsLink}`,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Privacy Policy',
    gaInstance: () => {
      Event('Link', `Click on ${links.privacyLink}`, 'Privacy Policy');
    },
    href: `${links.privacyLink}`,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Careers',
    gaInstance: () => {
      Event('Link', `Click on ${links.blogLink}/${links.careers}`, 'Careers');
    },
    href: `${links.careers}`,
    trackEvent: true,
    target: '_self',
  },
];

export const storeLinks = [
  {
    label: 'Sandton Store',
    gaInstance: () => {
      Event(
        'Link',
        `Click on ${locationLinks.sandtonCityLink}`,
        'Sandton Store',
      );
    },
    href: `${locationLinks.sandtonCityLink}`,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Waterfront Store',
    gaInstance: () => {
      Event(
        'Link',
        `Click on ${locationLinks.vaWaterfrontLink}`,
        'Waterfront Store',
      );
    },
    href: `${locationLinks.vaWaterfrontLink}`,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Gateway Store',
    gaInstance: () => {
      Event('Link', `Click on ${locationLinks.gatewayLink}`, 'Gateway Store');
    },
    href: `${locationLinks.gatewayLink}`,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Menlyn Store',
    gaInstance: () => {
      Event('Link', `Click on ${locationLinks.menlynMainLink}`, 'Menlyn Store');
    },
    href: `${locationLinks.menlynMainLink}`,
    trackEvent: true,
    target: '_blank',
  },
];

export const contactLinks = [
  {
    label: 'info@luxity.co.za',
    gaInstance: () => {
      Event('Link', `Click on mailto:info@luxity.co.za`, 'info@luxity.co.za');
    },
    href: 'mailto:info@luxity.co.za',
    trackEvent: true,
    target: '_blank',
  },
  {
    label: '010 020 6171',
    gaInstance: () => {
      Event('Link', `Click on tel:0100206171`, '010 020 6171');
    },
    href: 'tel:0100206171',
    trackEvent: true,
    target: '_blank',
    isContact: true,
  },
];

export const socialLinks = [
  {
    label: 'Instagram',
    gaInstance: () => {
      Event('Link', `Click on ${socialMediaLinks.instagram}`, 'Instagram');
    },
    href: socialMediaLinks.instagram,
    icon: '/assets/images/footer/instagram.png',
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Facebook',
    gaInstance: () => {
      Event('Link', `Click on ${socialMediaLinks.facebook}`, 'Facebook');
    },
    href: socialMediaLinks.facebook,
    icon: '/assets/images/footer/facebook.png',
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'TikTok',
    gaInstance: () => {
      Event('Link', `Click on ${socialMediaLinks.tiktok}`, 'TikTok');
    },
    href: socialMediaLinks.tiktok,
    icon: '/assets/images/footer/tiktok.png',
    trackEvent: true,
    target: '_blank',
  },
];
